<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h4 class="m-0 text-dark">Return Order of <b>{{ merchant_business }}</b></h4>
                         <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div>
                    <div class="col-md-2">
                        <div class="form-group" style="margin-top:28px;">
                            <button class="ml-1 btn btn-success btn-sm mr-2" @click="pdfDownload">PDF <i class="fa fa-download"></i></button>
                            <button class="btn btn-warning btn-sm" >                                            
                                <export-excel
                                    :data="dataCustomize"
                                    :fields= "json_fields"
                                    >
                                    Excel <i class="fa fa-download"></i>
                                </export-excel>
                            </button>
                        </div>
                    </div>
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table id="exportPdfTableHeader" class="table table-bordered" style="display:none">
                                            <thead>
                                                <tr>
                                                    <td>Merchant: {{ merchant_business }}</td>
                                                    <td style="text-align:right">Total Order: {{ orders.length }}</td>
                                                </tr>
                                            </thead>
                                        </table>
                                        <table id="exportPdfTable" class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('globalTrans.order_id') }}</th>
                                                    <th>{{ $t('globalTrans.date') }}</th>
                                                    <th>{{ $t('globalTrans.ref_id') }}</th>
                                                    <th>{{ $t('globalTrans.customer') }}</th>
                                                    <th>{{ $t('globalTrans.price') }}</th>
                                                    <th>{{ $t('globalTrans.collected') }}</th>
                                                    <th>{{ $t('globalTrans.service_charge') }}</th>
                                                    <th>{{ $t('globalTrans.return_charge') }}</th>
                                                    <th>{{ $t('globalTrans.weight_charge') }}</th>
                                                    <th>{{ $t('globalTrans.cod') }}</th>
                                                    <th>{{ $t('globalTrans.amount') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(order, index) in orders" :key="index">
                                                    <td @click="orderLog(order.logs)">{{ order.id }}</td>
                                                    <td>{{ order.date | dateformat }}</td>
                                                    <td>{{ order.ref_id }}</td>
                                                    <td>{{ order.name }}<br/>{{ order.mobile }}</td>
                                                    <td>{{ order.price }}</td>
                                                    <td>{{ order.collected }}</td>
                                                    <td>{{ order.service_charge }}</td>
                                                    <td>{{ order.return_charge }}</td>
                                                    <td>{{ order.weight_charge }}</td>
                                                    <td>{{ order.cod }}</td>
                                                    <td>{{ order.amount }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <!-- /.content -->
        <div v-if="orderLogModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Log</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered table-sm">
                            <tr>
                                <th style="width:20%">Action By</th>
                                <th style="width:55%">Action</th>
                                <th style="width:25%">Date & Time</th>
                            </tr>
                            <tr v-for="(log, index) in order_logs" :key="index">
                                <td>{{ log.operation_by }}</td>
                                <td>{{ log.operation }}</td>
                                <td>{{ log.date | dateformat }} at {{ log.date | timeformat }}</td>
                            </tr>
                        </table>                      
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import config from '@/config'
    // import jsPDF from 'jspdf'
    import 'jspdf-autotable'    
    import ExportPdf from './ReturnPdf'

    const excelColumn = {
        'Order ID': 'order_id',
        'SKU': 'order_sku',
        'Reference ID': 'order_reference_id',
        'Customer': 'order_customer',
        'Price': 'order_price',
        'Collected': 'order_collected',
        'Service Charge': 'order_service_charge',
        'Return Charge': 'order_return_charge',
        'Area Charge': 'order_area_charge',
        'Weight Charge': 'order_weight_charge',
        'COD': 'order_cod',
        'Amount': 'order_amount'
    }
    export default {
        name:'MakePaymentOrder',
        data(){
            return {   
                orderLogModal:false,
                order_logs:[],
                loader: true,             
                orders:[],
                key:'',
                merchant_business: '',
            }
        },
        created(){
            this.getOrders();
        },
        computed: {
            json_fields: function () {
                return excelColumn
            },
            dataCustomize () {
                return this.orders.map(item => {
                    item.order_id = item.id,
                    item.order_sku = item.sku,
                    item.order_reference_id= item.ref_id,
                    item.order_customer = item.name +" "+item.mobile,
                    item.order_price= item.price,
                    item.order_collected= item.collected,
                    item.order_service_charge= item.service_charge,
                    item.order_return_charge= item.return_charge,
                    item.order_area_charge= item.area_charge,
                    item.order_weight_charge= item.weight_charge,
                    item.order_cod= item.cod,
                    item.order_amount= item.amount

                    return Object.assign({}, item)
                })
            }
        },
        methods:{
            searchData () {
                this.getOrders()
            },
            async getOrders(){     
                this.loader = true     
                const response = await config.getData(`/rider/return/details/${this.$route.query.id}`)
                this.loader = false
                if (response.success){
                    this.orders = response.data
                    this.merchant_business = response.merchant
                } else {
                    this.orders = []
                    this.merchant_business = ''
                } 
            },
            pdfDownload() {
                // this.loader = true
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                ExportPdf.returnPdfDetails(base64Logo, this.orders, this.merchant_business)
                // this.loader = false
            },
            getBase64Logo (logo) {
                var canvas = document.createElement('canvas')
                canvas.width = logo.width
                canvas.height = logo.height
                var ctx = canvas.getContext('2d')
                ctx.drawImage(logo, 0, 0)
                var dataURL = canvas.toDataURL('image/png')
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
            },
            orderLog (logs) {
                this.order_logs = logs  
                this.orderLogModal = true
            },
            cancelModal () {
                this.orderLogModal = false
            }
        }
    }
</script>

<style scoped>
    @media only screen and (max-width: 760px),
	(min-device-width: 768px) and (max-device-width: 1024px)  {
	
		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr { 
			display: block; 
		}

        .table-sm th, .table-sm td {
            padding: 0.3rem;
            padding-left: 50%;
        }
		
		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		
		tr { 
            border: 1px solid #ccc;
            margin-bottom: 7px; 
        }
		
		td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			padding-left: 50%; 
		}
		
		td:before { 
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
		}
		
		/*
		Label the data
		*/
		td:nth-of-type(1):before { content: "অর্ডার আইডি" }
		td:nth-of-type(2):before { content: "তারিখ"; }
		td:nth-of-type(3):before { content: "ক্রেতা"; }
		td:nth-of-type(4):before { content: "মূল্য"; }
		td:nth-of-type(5):before { content: "সংগৃহীত"; }
		td:nth-of-type(6):before { content: "সার্ভিস চার্জ"; }
		td:nth-of-type(7):before { content: "ফেরৎ চার্জ"; }
		td:nth-of-type(8):before { content: "ওজন চার্জ"; }
		td:nth-of-type(9):before { content: "সিওডি চার্জ"; }
		td:nth-of-type(10):before { content: "টাকার পরিমান"; }
	}
	
	/* Smartphones (portrait and landscape) ----------- */
	@media only screen
	and (min-device-width : 320px)
	and (max-device-width : 480px) {
		body { 
			padding: 0; 
			margin: 0; 
			width: 320px; }
		}
	
	/* iPads (portrait and landscape) ----------- */
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		body { 
			width: 495px; 
		}
	}
</style>